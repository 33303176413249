import "./App.css";
import React, { useEffect, useState } from "react";
import logo from "./Assets/logo.png";
import social1 from "./Assets/social1.svg";
import social2 from "./Assets/social2.svg";
import meta from "./Assets/meta.svg";
import connect from "./Assets/connect.svg";
import { useWeb3React } from "@web3-react/core";
import { injected, walletConnect } from "./connectors";
import Web3 from "web3";
import { rpcUrl, contractAddress, abi } from "./contracts";

function App() {
  const [open, setOpen] = useState(false);
  const [vault, setVault] = useState("0");
  const [ownBalance, setOwnBalance] = useState("0");
  const [seeds, setSeeds] = useState("0");
  const [bag, setBag] = useState("0");
  const [profit, setProfit] = useState("0");
  const { account, activate, active, library } = useWeb3React();
  const [referalLink, setReferalLink] = useState("");

  const symbol = "BROCK";

  useEffect(() => {
    getContractBeans();
    if (account !== undefined) {
      getWalletBeans();
    }
  }, []);

  useEffect(() => {
    if (account !== undefined) {
      getSeedValues();
      setReferalLink("https://grower.green/" + account);
      getWalletBeans();
    }
  }, [account]);

  async function Connect() {
    try {
      await activate(injected);
      setOpen(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function WalletConnect() {
    try {
      await activate(walletConnect);
      setOpen(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function switchNetwork() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: Web3.utils.toHex(7171),
              chainName: "Bitrock Mainnet",
              rpcUrls: "https://connect.bit-rock.io",
              nativeCurrency: symbol,
              blockExplorerUrls: "https://explorer.bit-rock.io/",
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getContractBeans() {
    const web3 = new Web3(rpcUrl);
    let balance = await web3.eth.getBalance(contractAddress);
    balance = parseFloat(web3.utils.fromWei(balance)).toFixed(1);
    setVault(balance);
  }

  async function getWalletBeans() {
    const web3 = new Web3(rpcUrl);
    let balance = await web3.eth.getBalance(account);
    balance = parseFloat(web3.utils.fromWei(balance)).toFixed(4);
    setOwnBalance(balance);
  }

  async function checkValid(addy) {
    const web3 = new Web3(rpcUrl);
    console.log(web3.utils.isAddress(addy));
    return web3.utils.isAddress(addy);
  }

  async function plantSeeds() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);

    if (account == undefined) {
      return window.alert("Connect Wallet First");
    }

    console.log(seeds);
    if (seeds == "0") {
      return window.alert("Input how many " + symbol + " do you want to spend on seeds");
    }

    let referal;

    if (window.location.pathname == "/") {
      referal = account;
    } else if ((await checkValid(window.location.pathname.replace("/", ""))) == false) {
      referal = account;
    } else if ((await checkValid(window.location.pathname.replace("/", ""))) == true) {
      console.log(window.location.pathname.replace("/", ""));
      referal = window.location.pathname.replace("/", "");
    }

    contract.methods
      .buyEggs(referal)
      .send({
        from: account,
        to: contractAddress,
        value: web3.utils.toWei(seeds),
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function compound() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);

    let referal;

    if (window.location.pathname == "/") {
      referal = account;
    } else if (!checkValid(window.location.pathname.replace("/", ""))) {
      referal = account;
    } else if (checkValid(window.location.pathname.replace("/", ""))) {
      referal = window.location.pathname.replace("/", "");
      console.log(referal);
    }
    contract.methods
      .hatchEggs(referal)
      .send({
        from: account,
        to: contractAddress,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function sellEggs() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);

    contract.methods
      .sellEggs()
      .send({
        from: account,
        to: contractAddress,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function getSeedValues() {
    const web3 = new Web3(rpcUrl);
    const contract = new web3.eth.Contract(abi, contractAddress);

    const bagg = await contract.methods.hatcheryMiners(account).call();
    setBag(bagg);

    const ownedEggs = await contract.methods.getMyEggs().call({ from: account });

    if (ownedEggs == "0") {
      return;
    }
    let calcEggsPrice = await contract.methods.calculateEggSell(ownedEggs).call();

    calcEggsPrice = web3.utils.fromWei(calcEggsPrice);

    setProfit(calcEggsPrice);
  }

  function createReferal() {
    console.log();
  }
  createReferal();

  return (
    <div className="App">
      <div onClick={() => setOpen((prev) => !prev)} className={`overlay ${open ? "display-flex" : ""}`}></div>
      <div className={`pop-up ${open ? "display-flex" : ""}`}>
        <div onClick={Connect} className="pop">
          <img src={meta} alt="" />
          <h2>MetaMask</h2>
        </div>
        <div onClick={WalletConnect} className="pop">
          <img src={connect} alt="" />
          <h2>Wallet Connect</h2>
        </div>
      </div>
      <div className="top-app">
        <img src={logo} alt="" />
        <p>Get stinking rich Growing Green</p>
      </div>
      <div className="bottom-app">
        <div className="box">
          <div className="row">
            <p>Total Weight</p>
            <p>{vault} KG</p>
          </div>
          <div className="row">
            <p>Your Wallet</p>
            <p>
              {ownBalance} {symbol}
            </p>
          </div>
          <div className="row">
            <p>Your Seeds</p>
            <p>{bag} SEEDS</p>
          </div>
          <input type="text" onChange={(e) => setSeeds(e.target.value)} className="input" />
          <div className="btn-div">
            {active ? (
              <button className="connect">Connected</button>
            ) : (
              <button onClick={() => setOpen((prev) => !prev)} className="connect">
                connect Wallet
              </button>
            )}
            <button onClick={plantSeeds} className="yellow-btn">
              Plant Seeds
            </button>
          </div>

          <div className="btn-div">
            <button onClick={compound} className="compound">
              Feed/Compound
            </button>
            <button onClick={sellEggs} className="yellow-btn">
              Sell Green
            </button>
          </div>
          <div className="row">
            <p>Profit Made</p>
            <p>
              {profit} {symbol}
            </p>
          </div>
        </div>
        <div className="box">
          <h2>Yield/Consumables</h2>
          <div className="row">
            <p>Daily Profit</p>
            <p>10%</p>
          </div>
          <div className="row">
            <p>APR</p>
            <p>3650%</p>
          </div>
          <div className="row">
            <p>Pay Off Cops</p>
            <p>10%</p>
          </div>
        </div>
        <div className="box">
          <h2>Referral Link</h2>
          <input type="text" className="input" value={referalLink} readOnly />
          <p className="note">
            Receive 10% Straight into your profits , From any customers Brought by your referral code
          </p>
        </div>
        <div className="box last-box">
          <p className="note2">Please note: You can not unstake; only withdraw earnings.</p>
          <ul>
            <li>🍀 Tokenomics:</li>
            <li>🍀 Rewards can be re-invested & withdrawn daily.</li>
            <li>🍀 To ensure the max rewards (10% we suggest the following strategy:</li>
            <li>🍀 Plant Seeds (deposit {symbol})</li>
            <li>🍀 Feed weed 6 days per week </li>
            <li>🍀 Sell weed 1 day per week</li>
            <li>
              🍀 Every deposit/withdrawal has a base tax of 10% to ensure contract longevity, along with an insurance
              fee
            </li>
          </ul>
        </div>
        <div className="social-div">
          <a target="_blank" href="https://explorer.bit-rock.io/address/0x8421fA942A27c7F6314889554847A18a6c6A0348">
            <img src={social1} alt="" />
          </a>
          <a target="_blank" href="https://t.me/greengrowerbitrock">
            <img src={social2} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
